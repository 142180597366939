<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
<!--					<el-form-item label="关键字:">-->
<!--						<el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils.validForbid(e))" placeholder="项目编号/项目名称" clearable></el-input>-->
<!--					</el-form-item>-->
<!--					<el-form-item label="">-->
<!--						<el-select v-model="filters.areaStr" placeholder="请选择省份" filterable clearable>-->
<!--							<el-option-->
<!--									v-for="item in areaArr"-->
<!--									:key="item.id"-->
<!--									:label="item.name"-->
<!--									:value="item.code"-->
<!--							>-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--					<el-form-item label="">-->
<!--						<el-select v-model="filters.status" placeholder="指派类型" clearable>-->
<!--							<el-option-->
<!--									v-for="item in entyTypeArr"-->
<!--									:key="item.value"-->
<!--									:label="item.name"-->
<!--									:value="item.value"-->
<!--							>-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
<!--					<el-form-item label="">-->
<!--						<el-select v-model="filters.ddIdNodeBizType" placeholder="业务属性" clearable>-->
<!--							<el-option-->
<!--									v-for="item in projectStatus"-->
<!--									:key="item.value"-->
<!--									:label="item.name"-->
<!--									:value="item.value"-->
<!--							>-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				style="width: 100%"
				v-loading="listLoading"
				ref="multipleTable"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
			<!-- <el-table-column type="selection" width="45"></el-table-column> -->
			<el-table-column prop="projectNo" label="项目编号" align="center" width="160" show-overflow-tooltip></el-table-column>
			<el-table-column prop="areaData" label="所属县（区）" align="center" width="260" show-overflow-tooltip></el-table-column>
			<el-table-column prop="projectName" label="项目名称" align="center" min-width="160" show-overflow-tooltip>
				<template slot-scope='scope'>
					<el-button @click="viewsEntyTemplate(scope.row)" type="text">{{scope.row.projectName}}</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="ddIdNodeBizType" label="业务类型" align="center" show-overflow-tooltip width="160">
				<template slot-scope='scope'>
					<el-tag v-if="scope.row.ddIdNodeBizType === 1" type="success">图审</el-tag>
					<el-tag v-if="scope.row.ddIdNodeBizType === 10" type="danger">验收</el-tag>
					<el-tag v-if="scope.row.ddIdNodeBizType === 100" type="danger">开业检查</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="onlineEngineer1" label="在线工程师1" align="center" width="160" show-overflow-tooltip>
				<template slot-scope='scope'>
					{{scope.row.onlineEngineer1.engineerName}}
				</template>
			</el-table-column>
			<el-table-column prop="onlineEngineer2" label="在线工程师1" align="center" width="160" show-overflow-tooltip>
				<template slot-scope='scope'>
					{{scope.row.onlineEngineer2.engineerName}}
				</template>
			</el-table-column>
			<el-table-column prop="projectArea" label="项目认领" width="160" align="center" show-overflow-tooltip>
				<template slot-scope='scope'>
					<el-button @click="getAssignList(scope.row)" type="text">{{'认领'}}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<!-- 查看 -->
		<el-dialog
				title="查看"
				:visible.sync="viewsApplicationDialog"
				v-model="viewsApplicationDialog"
				:close-on-click-modal="false"
				:width='viewsApplicationWidth'
		>
			<ProjectView @activeChange="activeChange" :needData="needData"></ProjectView>
		</el-dialog>
	</div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import WordTable from "../drProjectManager/wordTable";
import ShowOpinionComponent from '../checkingManager/showOpinionComponent.vue'
import { getButtonList } from "../../promissionRouter";
import {
	getByNamespace,
	getDrProjectFiledtList,
	conclusionProject,
	getProjectParticipants,
	getAreaDictionaryList,
	updateProjectInfo,
	getStageListByProjectId,
	getProjectFileInfoByFlowNodeId,
	resetDrawingReviewApi,
	getProjectMessageByProjectId, addProjectMessage, getAssignList, acceptKYJCTask
} from '@/api/api'
import ProjectView from "../drProjectManager/projectView.vue";
import PreliminaryReport from "../checkingManager/preliminaryReportComponent.vue"
import AuditReportComponent from "../checkingManager/auditReportComponent"
import ReviewReport from "../checkingManager/reviewReportComponent.vue"
import { isValidContactNumber } from '../../../util/validate'
export default {
	components:{Toolbar, WordTable, ProjectView,PreliminaryReport,ReviewReport, AuditReportComponent,ShowOpinionComponent},
	data() {
		return {
			utils: '',
			buttonList: [],
			needData: [],
			areaArr: [],
			viewsApplicationWidth: '60%',
			filters: {
				keyWord: undefined,
				provinceCode:undefined,
				status:undefined,//指派类型
				ddIdNodeBizType:undefined,//业务属性（ 1：图审 10：验收 100：开业检查 ）
			},
			projectStatus: [
				{name: '图审',value: 1},
				{name: '验收',value: 10},
				{name: '开业检查',value: 100},
			],
			entyTypeArr: [
				{name: '正常',value: 56},
				{name: '冻结',value: 57},
			],
			tableData: [],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			// 项目查看
			viewsApplicationDialog: false,
		}
	},
	methods: {
		//受领任务
		getAssignList (row) {
			let params = {
				projectId:row.id
			}
			this.$confirm('确定认领此项目？',"提示",{
			}).then(() => {
				acceptKYJCTask(params).then(res => {
					const result = res.data
					if (result.success) {
						this.$message({
							type: 'success',
							message: result.message
						})
					} else {
						this.$message({
							type: 'error',
							message: result.message
						})
					}
				})
			})
		},
		rowClass () {
			return 'padding:8px!important;'
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		activeChange(val) {
			if(val == '2') {
				this.viewsApplicationWidth = "1200px"
			} else {
				this.viewsApplicationWidth = "60%"
			}
		},
		// 列表查询接口
		getListData(){
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				searchKey: this.filters.keyWord,
				provinceCode: this.filters.provinceCode,
				status:this.filters.status,//指派类型
				ddIdNodeBizType:this.filters.ddIdNodeBizType,//业务属性（ 1：图审 10：验收 100：开业检查 ）
			}
			if(this.filters.entyType){
				params.conclusionStatus = this.filters.entyType;
			}
			this.listLoading = true
			getAssignList(params).then(res => {
				var result = res.data
				if(result.success) {
					this.tableData = result.response.data
					this.pages.dataCount = result.response.dataCount
				}
				this.listLoading = false
			})
		},
		// 获取省
		getAreaList() {
			var params = {
				parentId: 1,
			}
			getAreaDictionaryList(params).then(res => {
				if (res.data.success) {
					this.areaArr = res.data.response.reverse();
				}
			})
		},
		// 查询
		getProjectList() {
			this.pages.pageIndex = 1
			this.getListData();
		},
		viewsEntyTemplate(row) {
			this.needData = row
			this.viewsApplicationDialog = true
		},
	
		// 点击table某一行
		currentChange(val) {
			this.needData = val
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getListData();
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			var currentPage = this.pages.pageIndex
			var pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
	
	},
	created(){
		this.utils = util
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getListData();
		// this.getAreaList()
	}
}
</script>

<style lang="stylus" scoped>
.projectName {
	text-align: center;
}
.projectSelect {
	margin-bottom: 15px;
}
.projectTitle {
	margin-bottom: 15px
}
.replyBtn {
	margin: 10px 0;
	color: #409eff;
	cursor: pointer;
	width: 30px;
}
.replyMessage {
	background: #eee;
	line-height: 30px;
	padding: 5px;
	border-radius: 2px;
	margin: 5px 15px;
}
.titleTimer {
	margin-left: 10px;
}
.green_t {
	color: green;
}
.red_t {
	color: red;
}
</style>
